import React from 'react'

import {Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import video from '../../assets/videos/articles/Nan/IMG_0726.MOV';
import slide1_1 from '../../assets/images/articles/Nan/NAN_slide1-1.jpg';
import slide1_2 from '../../assets/images/articles/Nan/NAN_slide1-2.jpg';
import slide1_3 from '../../assets/images/articles/Nan/NAN_slide1-3.jpg';
import slide1_4 from '../../assets/images/articles/Nan/NAN_slide1-4.jpg';
import slide1_5 from '../../assets/images/articles/Nan/NAN_slide1-5.jpg';
import slide1_6 from '../../assets/images/articles/Nan/NAN_slide1-6.jpg';
import slide1_7 from '../../assets/images/articles/Nan/NAN_slide1-7.jpg';
import slide1_8 from '../../assets/images/articles/Nan/NAN_slide1-8.jpg';

import slide2_1 from '../../assets/images/articles/Nan/NAN_slide2-1.jpg';
import slide2_2 from '../../assets/images/articles/Nan/NAN_slide2-2.jpg';
import slide2_3 from '../../assets/images/articles/Nan/NAN_slide2-3.jpg';

import slide3_1 from '../../assets/images/articles/Nan/NAN_slide3-1.png';
import slide3_2 from '../../assets/images/articles/Nan/NAN_slide3-2.png';
import slide3_3 from '../../assets/images/articles/Nan/NAN_slide3-3.png';

import slide4_1 from '../../assets/images/articles/Nan/NAN_slide4-1.png';
import slide4_2 from '../../assets/images/articles/Nan/NAN_slide4-2.png';
import slide4_3 from '../../assets/images/articles/Nan/NAN_slide4-3.png';
import slide4_4 from '../../assets/images/articles/Nan/NAN_slide4-4.png';
import slide4_5 from '../../assets/images/articles/Nan/NAN_slide4-5.png';
import slide4_6 from '../../assets/images/articles/Nan/NAN_slide4-6.png';
import slide4_7 from '../../assets/images/articles/Nan/NAN_slide4-7.png';
import slide4_8 from '../../assets/images/articles/Nan/NAN_slide4-8.png';
import slide5_1 from '../../assets/images/articles/Nan/NAN_slide5-1.png';
import slide5_2 from '../../assets/images/articles/Nan/NAN_slide5-2.png';
import slide6_1 from '../../assets/images/articles/Nan/NAN_slide6-1.png';


import side1 from '../../assets/images/articles/Nan/NAN_side1.jpg'
import side2 from '../../assets/images/articles/Nan/NAN_side2.jpg'


import downloadArticle from '../../assets/pdf/transition-spaces.pdf';

// Import Swiper React components


import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, EffectCreative, EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid black',
      borderRadius: '1px',
    },
}));

function Nan() {
  return (
    <div className='Nan'>
        <div className='Nan__backgroundVideo'>
        <div className='Nan__backgroundVideo__wrapper'>
            <div className='Nan__backgroundVideo__title'>
                <h1 className='editH1'>Night-After-Night</h1>
                <h2 className='editH2'>Lars Marcoen | 28.09.2024</h2>
            </div>
        </div>
        <video src={video} width="100%" height="auto" loop autoPlay muted playsInline></video>
      </div>

      <div className='Nan__container'>
        <div className='Nan__container__title'>
          <h1 className='editH1'>Night-After-Night</h1>
          <h2 className='editH2'>Lars Marcoen | 28.09.2024</h2>
        </div>

        <div className='Nan__container__intro'>
            <div className='Nan__intro__text'>
              The phenomenon of dance or night clubs and their club culture is undoubtedly an interesting topic from 
              both an anthropological and design point of view. Mateo Kries, director and curator at Vitra, describes 
              in the introduction to Nightfever; a collection about club culture in 2018, the essence of the club: "Clubs 
              are bakermats of popular culture, incubators of the avant-garde and places where mainstream social norms 
              are challenged. The economic and cultural development of society has gradually transformed the idea of 
              entertainment, and consequently also the spaces created and designed for it.<HtmlTooltip title="Diamanti, E. (2017). The Parabola of Italian Discotheques: from Radical Architecture to Spaghetti Dance. Scapegoat: architecture, landscape, political economy, 135–150." placement="bottom-start"><sup className='tooltip_ref'>1</sup></HtmlTooltip> "
              In the last six decades, the facets of club culture have been extraordinarily varied and diverse. Yet they appear to 
              have one thing in common: they all represent radical change, unconventional thinking and daring experimentation. 
              As a result, many clubs are developing into a gesamtkunstwerk, involving architecture, interior and 
              furniture design, graphic design, art, light, music, fashion and performance merging into a sensational whole. 
              Within the club, design is not limited to the design and production of everyday objects, but rather a 
              total experience is created. In this case, an unforgettable club night in which people surrender to 
              the night and immerse themselves in a continuum of sound, light and collective movement.
              <HtmlTooltip title="P., Schrager, I., Kries, M., Eisenbrand, J., & Serulus, K. (2018). Night Fever: Designing Club Culture 1960–Today. Vitra Design Museum." placement="bottom-start"><sup className='tooltip_ref'>2</sup></HtmlTooltip>
              <br /><br />
              The club is more than a black box.<HtmlTooltip title="Bieber, A., Leloup, J. Y., Museum Kunstpalast, Ott, B., & Holtkamp, A. (2021). Electro. Kunstpalast." placement="bottom-start"><sup className='tooltip_ref'>3</sup></HtmlTooltip> What 
              takes place inside is a reflection of the contemporary zeitgeist. Yet it is a space that is 
              separated from the outside world. This separation is reinforced by its opposite use of time - a 
              space of night rather than day - and its identity as a place 
              of leisure rather than work. Consequently, the club can be understood as a heterotopia. This is a term 
              coined by French theorist Michel Foucault <HtmlTooltip title="Michel Foucault. (1984). Of Other Spaces, Heterotopias. Routledge." placement="bottom-start"><sup className='tooltip_ref'>4</sup></HtmlTooltip> that 
              stands for "realized utopias" within society (relative 
              to utopias in our minds). The importance of this self-contained, temporary space, may explain the freedom 
              that club culture can offer in general; and thus can also be wielded as a designer.
              <HtmlTooltip title="P., Schrager, I., Kries, M., Eisenbrand, J., & Serulus, K. (2018). Night Fever: Designing Club Culture 1960–Today. Vitra Design Museum." placement="bottom-start"><sup className='tooltip_ref'>2</sup></HtmlTooltip>
            </div>
        </div>

        <div className="Nan__container__body">
            <div className='Nan__body__text__middle' id='get__H2'>
              <h2>Field research: Intuïtieve Mapping</h2>
              The spaces (of the night) are characterized by smoke, light, sound, temperature and dynamic bodies. So it is more than rigid design, attached objects, defined finishes or impenetrable surfaces. It clearly takes a different architectural and design narrative to create the spaces of the night and the fundamental rituals they stage.
              <HtmlTooltip title="P., Schrager, I., Kries, M., Eisenbrand, J., & Serulus, K. (2018). Night Fever: Designing Club Culture 1960–Today. Vitra Design Museum." placement="bottom-start"><sup className='tooltip_ref'>2</sup></HtmlTooltip> 
              <br /><br />
              ‘Lars Marcoen went clubbing and all he took home was this map’. 
              <br /><br />
              Examining tools to document a club night, looking for data to be collected, research can miss the most crucial point: the experience. The white lab coat was swapped for a flamboyant outfit, with danceable Nikes underneath. All we did was party hard until deep into the night. Only to realize the morning after, we didn’t collect anything at all. Trusting our intuïton, we drew the floor plan, mapping and marking the flow and psychogeography of the spaces, the dance floor, the entrance and other thresholds. Not a single measurement is ‘correct’ (in the ‘correct’ sense of the word).
              <br /><br />
              It made sense that artistic techniques of the Situationists were consulted. Guy Debord described the "dérive" as a wandering through changing environments and atmospheres of the city, in which we joyfully surrender to the stimulations and emotional effects of whatever we happen to encounter on the journey. The spatial patterns and effects of such wanderings were mapped by the discipline of what he called psychogeography. By following a set of rules, wanderers were guided by the feelings a place caused, rather than by following a predetermined course or the shortest path between two points to follow. 
              <HtmlTooltip title="Debord, G. (1958). Théorie de la dérive. Internationale Situationniste, 1958(2)." placement="bottom-start"><sup className='tooltip_ref'>5</sup></HtmlTooltip>
            </div>
        </div>

        <Swiper
        slidesPerView={4}
        spaceBetween={25}
        navigation={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="Nan_slider"
      >
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider1' id="Nan-slider1_1" src={slide1_1}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider2' id="Nan-slider1_2" src={slide1_2}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider3' id="Nan-slider1_3" src={slide1_3}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider4' id="Nan-slider1_4" src={slide1_4}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider5' id="Nan-slider1_5" src={slide1_5}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider6' id="Nan-slider1_6" src={slide1_6}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider7' id="Nan-slider1_7" src={slide1_7}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider8' id="Nan-slider1_8" src={slide1_8}  alt=""/></SwiperSlide>
      </Swiper>

        {/* <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, EffectCreative, EffectCards ]}
        navigation
        keyboard={{
          enabled: true,
        }}
        mousewheel={true}
        grabCursor={true}
        effect={"cards"}
        cardsEffect={{
          perSlideOffset: 10,
          slideShadows: false,
          perSlideRotate: 5,
        }}
        direction={"horizontal"}       
        slidesPerView="1"
        initialSlide= "6"
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        >            
          <SwiperSlide>
            <img className='article__slider__image Nan-slider1' id="Nan-slider1_1" src={slide1_1}  alt=""/>
          </SwiperSlide>
              
        </Swiper>
 */}
        <div className="Nan__container__body">
          <div className='Nan__body__text__middle' id='get__H2'>
            <h2>conclusion</h2>
            By drawing up the floor plans and mapping the movement or flow we experienced as clubgoers, we concluded a visual distinction between established clubs such as Funke, Club Vaag and Charlatan and club nights organized by club collectives in multipurpose (polyvalent) spaces such as Tripostal Topia, Minus One and FARRM. 
            <br /><br />
            It is clear by the complexity that the established clubs are already thoughtfully designed for the specific purpose of clubbing and the polyvalent spaces are not. The established clubs are more interesting because there are more "nooks & crannies," many rooms to explore and more thresholds to wander through. 
            <br /><br />
            The epicenter of the club, the dance floor, is open. The complexity of the spaces and connections increases the more we distance ourselves from this center. 
            <br /><br />
            Lighting elements, rigging and decoration are present in the established clubs. What was discovered at the temporary venues is that little or no attention was paid to the general scenography of spaces. 
            <br /><br />
            In both established and one-night clubs, no attention was paid to the transition from the outside world to the world inside the club. However, detailed transitions within the established club itself existed. Dance floor - stairwell - toilets - bar - ...
            <br /><br />
            Through observations and experience, it became clear that our role is more valuable in the multipurpose spaces that take on a temporary club character than in the existing nightclubs.
            <br /><br />
            These Belgian temporary club nights are realized by bottom-up club collectives. These collectives, such as Burenhinder and NooitMeerNaarHuis or Grid, strive for a collective collaboration to reach clubgoers and let them experience avant-garde electronic music. Remarkably, the club collectives work Do-It-Yourself (DIY) with the resources at their disposal. The budget that can be spent per night remains limited, making it unprofitable to open their own club. They are occasionally asked by established clubs to organize club nights. Usually these collectives look for cheap, multipurpose venues where they can live up to their own identity during the night. The venue they turn into a club often has limitations and is only available the day of the event itself. To create a night-after-night innovative overall experience, they need improvement and innovation on a scenographic level that reflects their identity. 
            <br /><br />
            We ask ourselves: How can an environment be transformative night after night? How can minimal interventions create a maximum impact for the clubgoer?
          </div>
        </div>
        <div className="Nan__container__body">
          <div className='Nan__body__text__middle' id='get__H2'>
            <h2>NIEUWENHUYS</h2>
            Starting in 1956, visual artist Constant Nieuwenhuys worked, the first years together with Guy Debord, on New Babylon. New Babylon is a utopian futuristic model for the city as a new form of society. He worked for 20 years on a large amount of paintings, drawings, maps, texts and models. The idea was that increasing industrialization and automation would give people a lot of free time. For this homo ludens, Constant designed a continuously changing play space with labyrinth-shaped rooms with movable walls, stairs and bridges. His New Babylon city consists of a network of interconnected sectors that respond to the psycho-emotional states of its wanderers. New Babylon was a delirium of public space.
            <HtmlTooltip title="Nieuwenhuys, T., Stamps, L., McDonnell, S., Haags Gemeentemuseum, Hatje Cantz Verlag, Museo Nacional Centro de Arte Reina Sofía, & Cobra, Museum Voor Moderne Kunst (Amstelveen, N. (2016). Constant: New Babylon. Beltz Verlag." placement="bottom-start"><sup className='tooltip_ref'>6</sup></HtmlTooltip>
            <br /><br />
            The concepts of New Babylon are inspiring and applicable to the club that can be perceived as a dynamic space that focuses on play and leisure activities. It is a labyrinth shaped space, an endless network of interconnected zones and circuits with different ambiances, its modular and endlessly reworked nature always causes a unique experience, it is impossible for a visitor to live the same experience twice.
          </div>
        </div>

        <div className='NAN_article__flex'>
          <img className='article__slider__image Nan_slider_img Nan_slider1' id="Nan-slider2_1" src={slide2_1}  alt=""/>
          <img className='article__slider__image Nan_slider_img Nan_slider2' id="Nan-slider2_2" src={slide2_2}  alt=""/>
          <img className='article__slider__image Nan_slider_img Nan_slider3' id="Nan-slider2_3" src={slide2_3}  alt=""/>
        </div>

        {/* slide show */}
        {/* <Swiper
        slidesPerView={2}
        spaceBetween={30}
        navigation={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="Nan_slider"
      >
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider1' id="Nan-slider2_1" src={slide2_1}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider2' id="Nan-slider2_2" src={slide2_2}  alt=""/></SwiperSlide>
        <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider3' id="Nan-slider2_3" src={slide2_3}  alt=""/></SwiperSlide>
      </Swiper> */}

        <div className="Nan__container__body">
          <div className='Nan__body__text__middle' id='get__H2'>
            <h2>Détour Lewitt & Layher</h2>
            In 1968, Solomon (Sol) LeWitt wrote the "sentences on conceptual art," in which he indicated that the concept and idea are more important than the artwork itself and its realization.
            <HtmlTooltip title="Cuglietta, & Manasseh. (2022, 16 mei). Sol LeWitt. Wall drawings, works on paper, structures (1968 – 2002). Brussels Museums. Geraadpleegd op 22 mei 2022, van https://www.brusselsmuseums.be/en/exhibitions/sol-lewitt.-wall-drawings-works-on-paper-structures-1968-2002" placement="bottom-start"><sup className='tooltip_ref'>7</sup></HtmlTooltip>
            <br /><br />
            The cube fascinated Sol Lewitt from the beginning of his career. He used the form as a grammatical tool for his creations due to its easy-to-handle nature. In his view, this simple, "relatively uninteresting" form is, ironically, "versatile".<HtmlTooltip title="Cuglietta, & Manasseh. (2022, 16 mei). Sol LeWitt. Wall drawings, works on paper, structures (1968 – 2002). Brussels Museums. Geraadpleegd op 22 mei 2022, van https://www.brusselsmuseums.be/en/exhibitions/sol-lewitt.-wall-drawings-works-on-paper-structures-1968-2002" placement="bottom-start"><sup className='tooltip_ref'>7</sup></HtmlTooltip> Like the
            modular form explored through détouring Layher scaffolding material, Lewitt combines cubes in
            a modular manner to achieve iconic structures. An open grid is used in its creation. Over time,
            this grid becomes a tool to standardize and organize designs and ideas. From Sol Lewitt we learn
            to always work simultaneously in two as well as three dimensions. Personally, this is a method to
            better understand construction and alternate representations of flatness and three-dimensionality.<HtmlTooltip title="Cuglietta, & Manasseh. (2022, 16 mei). Sol LeWitt. Wall drawings, works on paper, structures (1968 – 2002). Brussels Museums. Geraadpleegd op 22 mei 2022, van https://www.brusselsmuseums.be/en/exhibitions/sol-lewitt.-wall-drawings-works-on-paper-structures-1968-2002" placement="bottom-start"><sup className='tooltip_ref'>7</sup></HtmlTooltip> Looking more closely at the series of works around the “incomplete
            open cubes,” we note that the cube is seen as a hexagon from the drawn perspective, a subtle
            trompe-l’oeil. This hexagon can be connected in various ways to achieve an innovative form. If we
            translate this directly into the three-dimensional structure, partitions or walls emerge that create an
            innovative environment.
            <br /><br />
            This renewing environment always comes with a new psychogeographic route. Providing the possibility to draw an ‘intuïtitve expectation mapping’ using our (trained) intuition. 
          </div>
        </div>

        <img className='article__slider__image Nan_slider_img Nan-slider6_1' id="Nan-slider6_1" src={slide6_1}  alt=""/>
        {/* <img className='article__slider__image Nan_slider_img Nan_slider3' id="Nan-slider2_3" src={slide2_3}  alt=""/> */}


        <div className='NAN_article__flex'>
          <img className='article__slider__image Nan_slider_img Nan-slider3_1' id="Nan-slider3_1" src={slide3_1}  alt=""/>
          <img className='article__slider__image Nan_slider_img Nan-slider3_1' id="Nan-slider3_2" src={slide3_2}  alt=""/>
          <img className='article__slider__image Nan_slider_img Nan-slider3_1' id="Nan-slider3_3" src={slide3_3}  alt=""/>
        </div>


        <Swiper
        slidesPerView={4}
        spaceBetween={30}
        navigation={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="Nan_slider Nan_slider_2"
        >
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider1' id="Nan-slider4_1" src={slide4_1}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider2' id="Nan-slider4_2" src={slide4_2}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider3' id="Nan-slider4_3" src={slide4_3}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider4' id="Nan-slider4_4" src={slide4_4}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider5' id="Nan-slider4_5" src={slide4_5}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider6' id="Nan-slider4_6" src={slide4_6}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider7' id="Nan-slider4_7" src={slide4_7}  alt=""/></SwiperSlide>
          <SwiperSlide><img className='article__slider__image Nan_slider_img Nan_slider8' id="Nan-slider4_8" src={slide4_8}  alt=""/></SwiperSlide>
        </Swiper>

        <div className="Nan__container__body" >
          <div className='Nan__body__text' >
            <div className='Nan__text__4'>
                <h2>NAN</h2>
                To demonstrate the modularity and transformative nature of the structure and inlays, the dressing of the skeleton can be changed throughout the consecutive club nights. Each day the scaffolding netting can be removed from the skeleton and suspended in a new fashion. This will renew the site of exploration and create a completely new psychogeographical experience every night. The graphically realized psycho geographical expectations are used as a design tool. Can we determine the movement of the clubbers through shaping the environment? What kind of ambiances can we create in the spaces? For what purpose do we place specific corridors and thresholds? For the experiment, it is possible to anticipate the activities within the structure and therefore within the space. During club nights, we can observe and analyze whether the space and elements are used as expected, or whether we suddenly provoke new reactions and actions. The aim of the experiment is not to design a different way of behavior, but opening up the possibilities of connections, experiences and actions.
            </div>
          </div>
          <div className='Nan__body__image'>
            <img src={side1} alt=""  />
            <img src={side2} alt=""  />
          </div>
        </div>

        <img className='article__slider__image Nan_slider_img Nan-slider5_1' id="Nan-slider6_1" src={slide5_1}  alt=""/>
        <img className='article__slider__image Nan_slider_img Nan-slider5_1' id="Nan-slider6_1" src={slide5_2}  alt=""/>

        <div className="Nan__container__body">
            <div className='Nan__body__text__middle' id='get__H2'>
                <h2>&#60;3</h2>
                The production of the experiment took place during Genk On Stage 2022, a local city festival
                providing an encounter with a broad spectrum of visitors. HeteKolen, the youth platform for Genk,
                invited Club Dérive to design the ‘club stage’, a stage taking place at Club26. This event and
                project can be regarded as the first Club Dérive project, and thus the birth of the network. Eternal
                gratitude towards HeteKolen, GenkOnStage, Luca School of Arts, in particular to Noemi Murru, Jelle
                Hoeben, Mathias Wouters & Lieven Menschaert.
                <br /><br />
                Immensely blessed with all the helping hands to build the monster: Vladimir Capri, Nathan Vrebos,
                Juul Prinsen, Kaat Fabri, Lola Ilegems, Fenia Proost, Rafael Daniëls, Luca Jeunen and Dries Marcoen
                <br /><br />
                My deepest gratitude to Cosima Rosie Lagae for all the unwavering support throughout this process. Thank you! 
                <br /><br />
                A massive thanks to Stan Vrebos and Pieterjan Ginckels for the feedback during the design and
                execution period.
                <br /><br />
                Sounddesign by Axemblem &#60;3
                <br /><br />
                Sadly, after the project was executed, the nights have not been documented well, and all the
                documentation that was captured, got lost or damaged. From this point on, documenting became a
                new sport for us, because: <b>document your culture!</b> 

            </div>
        </div>

        {/* <div className="Nan__container__body">
          <div className='Nan__body__text__middle middle__center' id='get__H2'>
            <div className='display__flex'>
              <div className='article__download__button'>
                <a href={downloadArticle} download="article_Night-After-Night_Lars-Marcoen"> Download Article</a>
              </div>
            </div>
          </div>
        </div> */}

        <div className="Nan__container__body">
            <div className='Nan__body__text__middle' id='get__H2'>
              <h2>Sources</h2>
              Diamanti, E. (2017). The Parabola of Italian Discotheques: from Radical Architecture to Spaghetti Dance. Scapegoat: architecture, landscape, political economy, 135–150.
              <br /><br />
              P., Schrager, I., Kries, M., Eisenbrand, J., & Serulus, K. (2018). Night Fever: Designing Club Culture 1960–Today. Vitra Design Museum.
              <br /><br />
              Bieber, A., Leloup, J. Y., Museum Kunstpalast, Ott, B., & Holtkamp, A. (2021). Electro. Kunstpalast.
              <br /><br />
              Michel Foucault. (1984). Of Other Spaces, Heterotopias. Routledge.
              <br /><br />
              Debord, G. (1958). Théorie de la dérive. Internationale Situationniste, 1958(2).
              <br /><br />
              Nieuwenhuys, T., Stamps, L., McDonnell, S., Haags Gemeentemuseum, Hatje Cantz Verlag, Museo Nacional Centro de Arte Reina Sofía, & Cobra
              <br />Museum Voor Moderne Kunst (Amstelveen, N. (2016). Constant: New Babylon. Beltz Verlag.
              <br /><br />
              Cuglietta, & Manasseh. (2022, 16 mei). Sol LeWitt. Wall drawings, works on paper, structures (1968 – 2002). Brussels Museums. Geraadpleegd op 22 mei 2022, van https://www.brusselsmuseums.be/en/exhibitions/sol-lewitt.-wall-drawings-works-on-paper-structures-1968-2002
            </div>
        </div>

      </div>
    </div>
  )
}

export default Nan