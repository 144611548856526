import './App.css';
import React from 'react'
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Outlet, useLocation } from 'react-router-dom'

import Home from './pages/Homepage'
import About from './pages/Aboutpage'
import Archive from './pages/Archivepage'
import Agenda from './pages/Agendapage'
import Contributers from './pages/Contributerspage'
import Insitus from './pages/Insituspage'
// import SingleInsitu from './pages/SingleInsitupage'
import Entrancepage from './pages/Entrancepage';
import Montage from './pages/articles/Montage';
import Transition from './pages/articles/Transition';
// import FlexHeader from './components/FlexHeader';

// import {loadEdit} from './scripts/styleEdit.js'


import Header from './components/Header';
import Comingsoonpage from './pages/Comingsoonpage';
import Toolkitweek from './pages/Toolkitweek.jsx';
import PostHuman from './pages/articles/PostHuman.jsx';
import TwoTowers from './pages/articles/TwoTowers.jsx';
import Woodblocks from './pages/articles/Woodblocks.jsx';
import Nan from './pages/articles/Nan.jsx';


function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
        <Route exact path='/' element={<Root />}>
            <Route path='/' element={<Home />} />
            <Route index path='/entrance' element={<Entrancepage />} />
            <Route index path='/coming' element={<Comingsoonpage />}/>
            <Route path="/about" element={<About />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/projects" element={<Insitus />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/contributers" element={<Contributers />} />
            {/* <Route path="/:slug" Component={SingleInsitu} /> */}
            <Route path="/null" element={<Contributers />} />

            {/* ARTICLES PATHS */}
            <Route path="/take-two-give-one" element={<Montage />} />
            <Route path="/transition-spaces" element={<Transition />} />
            <Route path="/post-human" element={<PostHuman />} />
            <Route path="/two-towers" element={<TwoTowers />} />
            <Route path="/woodblocks" element={<Woodblocks />} />
            <Route path="/night-after-night" element={<Nan />} />

            {/* LARS PATHS */}
            <Route path="/toolkitweek" element={<Toolkitweek />} />

        </Route>
    )
  )

  return (
      <div className='app'>
          <RouterProvider router={router} />
      </div>
  )
}

const Root = () => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  // console.log(splitLocation);

  if (splitLocation[1] === "insituateliers" ){
    var locationName = "true"
  }

  return (
    <>
      <Header />

      {/* <FlexHeader /> */}
      
      <div className={ locationName === "true"  ? "outletInsitu" : "outlet"}>
      {/* <div className="outlet"> */}
        <Outlet />
      </div>
    </>

  )
}

export default App;


