export const nanData = [
    {
        src: require("../images/articles/Nan/NAN_side1.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_side2.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-1.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-2.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-3.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-4.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-5.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-6.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-7.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide1-8.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide2-1.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide2-2.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide2-3.jpg"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide3-1.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide3-2.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide3-3.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-1.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-2.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-3.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-4.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-5.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-6.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-7.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide4-8.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide5-1.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide5-2.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },
      {
        src: require("../images/articles/Nan/NAN_slide6-1.png"),
        publishedAt: "2024-09-28",
        author: "Lars Marcoen",
        slug: "night-after-night",
        category: "article",
        order: "2024-09-28"
      },

]
  