import React, {useEffect} from 'react';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import obj from '../assets/models/lloss2.glb';

function Render4() {
    useEffect(() => {
        const win = document.querySelector('canvas.webglFour');
        // console.log(win);


        // CONTROLS CAMRA 
        win.addEventListener('dblclick', () => {
            const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

            if(!fullscreenElement){
            if(canvas.requestFullscreen){
                canvas.requestFullscreen()

                const controls = new OrbitControls(camera, canvas)
                controls.enableDamping = true
                controls.enableZoom= false
            } else if(canvas.webkitRequestFullscreen){
                canvas.webkitRequestFullscreen()

                const controls = new OrbitControls(camera, canvas)
                controls.enableDamping = true
                controls.enableZoom= false
            }
            }
            else{
            if(document.exitFullscreen){
                document.exitFullscreen()

                const controls = new OrbitControls(camera, canvas)
                controls.enableDamping = false
                controls.enableZoom= false
            } else if(document.webkitExitFullscreen){
                document.webkitExitFullscreen()
            }
            }
        })

  
        //CANVAS
        const canvas = document.querySelector('canvas.webglFour');
        const scene = new THREE.Scene();
    
        // scene.background = new THREE.Color(0x1fffff);
    

        // const render = new GLTFLoader();
        // render.load(obj, function(gltf) {  
        //     const obj = gltf.scene;
        //     // obj.position.y =  * 0
        //     obj.position.y = 0
        //     obj.position.z = 0
        //     obj.rotation.y = -2
        //     obj.scale.set(2,2,2)
        //     scene.add(obj);

        //     function animate(){
        //         requestAnimationFrame(animate)
        //         obj.rotation.y += 0.002;    
        //     }
        //     animate();
        // }, function(xhr){
        //     // console.log((xhr.loaded/xhr.total*1000)+"% loaded")
        // }, function(error){
        //     // console.log(error,'ERROR occured')
        // }) 

        // DRACO LOADER
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/'); // Path naar de Draco-bibliotheek

        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader); // Draco toepassen op GLTFLoader

        gltfLoader.load(
        obj,
        (gltf) => {
            const model = gltf.scene;
            model.rotation.y = -2
            model.scale.set(2, 2, 2);
            scene.add(model);

            // Animatie toevoegen aan het model
            function animateModel() {
            requestAnimationFrame(animateModel);
                model.rotation.y += 0.002;
                renderer.render(scene, camera);
            }
            animateModel();
        },
        (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% geladen - Model 4');
        },
        (error) => {
            console.error('Fout bij het laden van het model 4', error);
        }
        );
        
    
        

      
  
        // LIGHT
        const light = new THREE.DirectionalLight(0xffffff,4)
        light.position.set(0,3,20)
        scene.add(light)
    
        const light2 = new THREE.DirectionalLight(0xffffff,2)
        light2.position.set(0,3,-1)
        light2.rotation.x = 15;
        scene.add(light2)
    
    
        // WINDOW SIZE
        const sizes = {
            width: window.innerWidth-600,
            height: window.innerHeight
        }
        window.addEventListener('resize', () => {
            // Update sizes
            sizes.width = window.innerWidth-300;
            sizes.height = window.innerHeight;
    
            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()
    
            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })
    
    
        // CAMERA
        const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1,100);
        // camera.lookAt(new THREE.Vector3(0,13,0));
        camera.position.set(0,0,5);
        // camera.position.y = -13;
        camera.rotation.x = 0;
        camera.rotation.y = 0;
        camera.rotation.z = 0;
        scene.add(camera)
    
    
        //SCROLL
        let scrollY = window.scrollY
        window.addEventListener('scroll', () => {
            scrollY = window.scrollY
    
            // console.log(scrollY)
        })
    
    
        // CONTROLS
        const controls = new OrbitControls(camera, canvas)
        controls.enableDamping = true
        controls.enableZoom= false
    
    
        //RENDERER
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            alpha: true
        })
        renderer.setSize(sizes.width,sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
        renderer.shadowMap.enabled = true
        renderer.gammaOutput = true
        renderer.setClearColor(0x000000,0);
    
    
        //ANIMATION
        function animate(){
            requestAnimationFrame(animate)
            // camera.position.y = - scrollY / sizes.height*2 
    
            renderer.render(scene,camera)
        }
        animate() 
  
    }, [])

  return (
    <canvas class='webglFour' id='webglFour'></canvas>
  )
}

export default Render4