export const articleData = [
    {
        titlePartOne: "",
        titlePartTwo: "Transition spaces",
        author: "Lars Marcoen",
        imageURL: require("../images/articles/transitie/transition1.jpg"),
        publishedAt: "14.07.2022",
        slug: "transition-spaces",
        project: "transitie",
        order: "2022-07-14",
        category: "article"
    },
    {
        titlePartOne: "InSitu Atelier:",
        titlePartTwo: "Take Two, Give One",
        author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
        imageURL: require("../images/articles/montage/DSC05047.jpg"),
        publishedAt: "20.06.2023",
        slug: "take-two-give-one",
        project: "Montage",
        order: "2023-06-20",
        category: "article"
    },
    {
        titlePartOne: "InSitu Atelier:",
        titlePartTwo: "Post Human",
        author: "Lars Marcoen",
        imageURL: require("../images/articles/PostHuman/posthuman-party_2.jpg"),
        publishedAt: "09.05.2023",
        slug: "post-human",
        project: "Burenhinder",
        order: "2023-05-29",
        category: "article"
    },
    {
        titlePartOne: "Project:",
        titlePartTwo: "Two Towers - LLOSS",
        author: "",
        imageURL: require("../images/articles/TwoTowers/twotowers-party-vidstill_2.jpg"),
        publishedAt: "23-24.06.2023",
        slug: "two-towers",
        project: "LLOSS",
        order: "2023-06-23",
        category: "article"
    },
    {
        titlePartOne: "Project:",
        titlePartTwo: "Woodblocks - Kiosk Radio",
        author: "",
        imageURL: require("../images/articles/woodblocks/woodblocks-party-41.jpg"),
        publishedAt: "09-10.09-2023",
        slug: "woodblocks",
        project: "Kiosk Radio",
        order: "2023-09-09",
        category: "article"
    },
    {
        titlePartOne: "InSitu Atelier:",
        titlePartTwo: "Night-After-Night",
        author: "Lars Marcoen",
        imageURL: require("../images/articles/Nan/NAN_slide5-1.png"),
        publishedAt: "28.09.2024",
        slug: "night-after-night",
        project: "Club Dérive",
        order: "2024-09-28",
        category: "article"
    },

]
