import React  from 'react'
import {Link} from 'react-router-dom'


import RenderOne from '../renders/RenderOne';
import RenderTwo from '../renders/RenderTwo';
import Render3 from '../renders/Render3';
import Render4 from '../renders/Render4';
import Render5 from '../renders/Render5';
import Render6 from '../renders/Render6';


// import RenderOneMobile from '../renders/RenderOneMobile';
// import RenderTwoMobile from '../renders/RenderTwoMobile';

import NanImage from '../assets/images/articles/Nan/NAN_slide1-3.jpg';
import montageImage from '../assets/images/articles/montage/DSC05047.jpg';
import transitionImage from '../assets/images/articles/transitie/transition1.jpg';
import burenhinderImage from '../assets/images/articles/PostHuman/posthuman-party_3.jpg';
import llossImage from '../assets/images/articles/TwoTowers/twotowers-party-11.jpg';
import kioskImage from '../assets/images/articles/woodblocks/woodblocks-party-25.jpg';

import {Cloudinary} from "@cloudinary/url-gen";
// import FlexHeader from '../components/FlexHeader';


const cld = new Cloudinary({
  cloud: {
    cloudName: `${process.env.CLOUDINARY_CLOUD_NAME}`
  }
}); 


const montageVideo = cld.video('CLUB_DERIVE/ARTICLES/montage/videos/montage-background-video_cohstl');
const transitionVideo = cld.video('transition-background-video_ddo0e9');


function Insituspage() {
  
  if(!RenderOne) return <div className='loading'>Loading...</div>;

  return (
    <div className='insitus' id='background'>
      {/* <FlexHeader/> */}
      <div className='insitus__title editH1'>Projects & Articles</div>
      <div className='insitus__renders__containers renderhide'>
        <Render6/>
        <RenderOne/>
        <Render3/>
        <Render4/>
        <RenderTwo/>
        <Render5/>

      </div>
      <div className='insitus__renders__containers mobile'>
        {/* <RenderOneMobile/> */}
        {/* <RenderTwoMobile/> */}
      </div>

      <div className='insitus__video__containers mobile'>
        <div className='video__wrapper moet-nog-veranderen'> 
          <img src={NanImage} alt="" />
        </div>
        <div className='video__wrapper'> 
          <img src={montageImage} alt="" />
        </div>
        <div className='video__wrapper'>
          <img src={burenhinderImage} alt="" /> 
        </div>
        <div className='video__wrapper'>
          <img src={llossImage} alt="" /> 
        </div>
        <div className='video__wrapper'>
          <img src={transitionImage} alt="" /> 
        </div>
        <div className='video__wrapper'>
          <img src={kioskImage} alt="" /> 
        </div>
      </div>

      <div className='insitus__container'>
        <section className='insitus__section' id="nan">
          <Link to="/night-after-night">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>InSitu Atelier:</h1>
              <h1 className='editH1'>Night-After-Night</h1>
              <h2 className='editH2'> Lars Marcoen | 28.09.2024</h2>
            </div>
          </Link>
        </section>

        <section className='insitus__section' id="take-two-give-one">
          <Link to="/take-two-give-one">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>InSitu Atelier:</h1>
              <h1 className='editH1'>Take Two, Give One</h1>
              <h2 className='editH2'> Lars Marcoen & Bjorne Baeten & <br /> Jade Corbey | 20.06.2023</h2>
            </div>
          </Link>
        </section>

        <section className='insitus__section' id="post-human">
          <Link to="/post-human">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>InSitu Atelier:</h1>
              <h1 className='editH1'>Post Human</h1>
              <h2 className='editH2'> Lars Marcoen | 29.05.2023</h2>
            </div>
          </Link>
        </section>

        <section className='insitus__section' id="transition-spaces">
          <Link to="/two-towers">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>Project:</h1>
              <h1 className='editH1'>Two Towers - LLOSS</h1>
              <h2 className='editH2'>23-24.06.2023</h2>
            </div>
          </Link>
        </section>

        <section className='insitus__section' id="transition-spaces">
          <Link to="/transition-spaces">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>Transition spaces</h1>
              <h2 className='editH2'> Lars Marcoen | 14.07.2022</h2>
            </div>
          </Link>
        </section>

        <section className='insitus__section' id="transition-spaces">
          <Link to="/woodblocks">
            <div className='insitus__wrapper'>
              <h1 className='editH1'>Project:</h1>
              <h1 className='editH1'>Woodblocks - Kiosk Radio</h1>
              <h2 className='editH2'>9-10.09.2023</h2>
            </div>
          </Link>
        </section>


      </div>

    </div>
  )
}

export default Insituspage